import React, { useContext, useState } from 'react';
import { Paper } from '../Paper';
import { SUPPORTED_EVENTS, KeyboardSniffer, Container, SectionHeader, Grid } from '..';
import { Actions, TablePageButtonRow, TablePageButtonRowProps } from '../TablePageButtonRow';
import { TablePageTitleProps } from '../TablePageTitle';
import { DeleteModal } from '../DeleteModal/DeleteModal';
import { LabelsContext } from '../../context';
import { SupportedRowType } from '../CustomTable/CustomTable.types';

interface Props {
	actions: Actions;
	isLoading: boolean;
	title?: TablePageTitleProps["title"];
	renderButtons?: TablePageButtonRowProps["renderButtons"];
	handleAdd?: () => void;
	handleConfirmDelete?: () => Promise<void>;
	renderSelectors?: () => JSX.Element;
	renderFilterSection?: () => JSX.Element;
	renderGraphs?: () => (JSX.Element | undefined);
	renderOverview?: () => JSX.Element;
	renderChips?: () => JSX.Element;
	deleteAlertText: string;
	selectedLines: SupportedRowType[];
}

export const TablePageLayout: React.FunctionComponent<Props> = (props) => {
	const { actions, isLoading, title, renderButtons, deleteAlertText, children, selectedLines } = props;
	const { labels } = useContext(LabelsContext);
	const [showDeleteModal, showDeleteModalField] = useState(false);

	const toggleShowDeleteModal = () => {
		showDeleteModalField(!showDeleteModal);
	};

	const confirmDelete = async () => {
		if (actions.delete) await actions.delete();
		showDeleteModalField(false);
	};

	return (
		<Container maxWidth="xl">
			{props.handleAdd && <KeyboardSniffer handlers={{ [SUPPORTED_EVENTS.add]: props.handleAdd }} />}
			{actions && actions.add && <KeyboardSniffer handlers={{ [SUPPORTED_EVENTS.add]: actions.add }} />}
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<SectionHeader hasThemeSupport title={title || ''} />
				</Grid>
				<Grid item xs={12}>
					{renderSelectors(props)}
				</Grid>
				<Grid item xs={12} md={7} lg={7}>
					{renderGraphs(props)}
				</Grid>
				<Grid item xs={12} md={5} lg={5}>
					{renderOverview(props)}
				</Grid>
				<Grid item xs={12}>
					{renderChips(props)}
				</Grid>
				<Grid item xs={12}>
					{renderFilterSection(props)}
				</Grid>
				<Grid item xs={12}>
					<TablePageButtonRow
						actions={{
							add: actions.add,
							modify: actions.modify,
							delete: toggleShowDeleteModal,
							activate: actions.activate,
							reload: actions.reload,
							view: actions.view,
							flipChart: actions.flipChart,
							remove: actions.remove
						}}
						isLoading={isLoading}
						labels={labels}
						renderButtons={renderButtons}
						hasLineSelected={selectedLines.length === 1}
					/>
				</Grid>
				<Grid item xs={12}>
					<Paper className="padding-one-rem display-flex--column">
						{children}
					</Paper>
				</Grid>
			</Grid>
			<DeleteModal
				labels={labels}
				deleteAlertText={deleteAlertText || ''}
				handleClose={toggleShowDeleteModal}
				handleConfirmDelete={confirmDelete}
				showDeleteModal={showDeleteModal}
			/>
		</Container>
	);
}

const renderSelectors = ({ renderSelectors }: { renderSelectors?: Props["renderSelectors"] }) => {
	if (renderSelectors) return renderSelectors();
};

const renderGraphs = (props: Props) => {
	if (props.renderGraphs) return props.renderGraphs();
};

const renderOverview = (props: Props) => {
	if (props.renderOverview) return props.renderOverview();
};

const renderChips = (props: Props) => {
	if (props.renderChips) return props.renderChips();
};

const renderFilterSection = ({ renderFilterSection }: { renderFilterSection?: Props["renderFilterSection"] }) => {
	if (renderFilterSection) return renderFilterSection();
};