import './TablePageButtonRow.css';
import React from 'react';
import { Button, Icon, ICON_NAMES, ReloadButton } from '..';
import { AddButton } from '../AddButton';
import { ModifyButton } from '../ModifyButton';
import { DeleteButton } from '../DeleteButton';
import { RemoveButton } from '../remove-button/remove-button';

export interface Actions {
	add?: () => void;
	modify?: () => void;
	delete?: () => void;
	reload?: () => Promise<void>;
	activate?: () => void;
	view?: () => void;
	flipChart?: () => void;
	remove?: () => void;
}

export interface TablePageButtonRowProps {
	actions: Actions;
	isLoading: boolean;
	hasLineSelected: boolean;
	labels: {
		add: string;
		update: string;
		delete: string;
		reload: string;
		search: string;
		flipChart: string;
	},
	renderButtons?: () => JSX.Element;
}

export const TablePageButtonRow: React.FunctionComponent<TablePageButtonRowProps> = ({
	actions,
	isLoading,
	hasLineSelected,
	labels,
	renderButtons,
}) => {
	// TODO: Remove or refactor "if (props.buttons)"
	// TODO: Change markup for Activate button
	if (renderButtons) return renderButtons();
	return (
		<div className="table-page-button-row">
			{actions.add && (
				<AddButton disabled={isLoading} onClick={actions.add} labels={labels} />
			)}
			{actions.modify && (
				<ModifyButton disabled={isLoading || !hasLineSelected} onClick={actions.modify} labels={labels} />
			)}
			{actions.delete && (
				<DeleteButton disabled={isLoading || !hasLineSelected} onClick={actions.delete} labels={labels} />
			)}
			{actions.activate && (
				<AddButton disabled={isLoading || !hasLineSelected} onClick={actions.activate} labels={labels} />
			)}
			{actions.remove && (
				<RemoveButton disabled={isLoading || !hasLineSelected} onClick={actions.remove} labels={{ remove: 'Remove' }} />
			)}
			{actions.reload && (
				<ReloadButton disabled={isLoading} onClick={actions.reload} labels={labels} />
			)}
			{actions.view && (
				<Button
					variant="contained"
					color="primary"
					onClick={actions.view}
					disabled={isLoading || !hasLineSelected}
				>
					<Icon iconName={ICON_NAMES.search} />
					{labels.search}
				</Button>
			)}
			{actions.flipChart && (
				<Button
					variant="contained"
					color="primary"
					onClick={actions.flipChart}
					disabled={isLoading}
				>
					<Icon iconName={ICON_NAMES.pieChart} />
					{labels.flipChart}
				</Button>
			)}
		</div>
	);
}