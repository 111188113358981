import React from 'react';
import {
	Tv, LocalMovies, Star, CalendarToday, Language, PhoneAndroid, Home, Email, Work, Loop, Add, Create, Delete, Save, Cancel, Restaurant, Euro, PlaylistAddCheck, Build, Photo,
	PowerSettingsNew, ArrowBack, ArrowForward, KeyboardArrowDown, KeyboardArrowUp, MoreVert, PriorityHigh, Warning, Remove, ExpandLess, ExpandMore, AssignmentInd, Search, PieChart, BookRounded,
	Check, LocalLibrary, Hotel, FitnessCenter, RunCircleOutlined, Inventory, RemoveShoppingCart
} from '@mui/icons-material';
import { FaReact, FaNodeJs, FaGitlab, FaSass, FaLinkedin, FaGithub, FaFacebook } from 'react-icons/fa';
import { SiRedux, SiWebpack, SiMongodb } from 'react-icons/si';
import { MaterialUiLogo } from './material-ui-logo';

export enum ICON_NAMES {
	arrowDown = 'arrowDown',
	arrowLeft = 'arrowLeft',
	arrowRight = 'arrowRight',
	arrowUp = 'arrowUp',
	add = 'Add',
	book = 'book',
	Badge = 'Badge',
	Build = 'Build',
	cancel = 'Cancel',
	Cash = 'Cash',
	Check = 'Check',
	critical = 'PriorityHigh',
	dead = 'dead',
	delete = 'Delete',
	ExpandLess = 'ExpandLess',
	ExpandMore = 'ExpandMore',
	Gym = 'Gym',
	kebabMenu = 'kebabMenu',
	Language = 'Language',
	modify = 'Modify',
	Movies = 'Movies',
	Photo = 'Photo',
	Power = 'Power',
	Recipes = 'Recipes',
	search = 'Search',
	save = 'Save',
	Todo = 'Todo',
	warning = 'Warning',
	Work = 'Work',
	reload = 'reload',
	Tv = 'Tv',
	Star = 'Star',
	Calendar = 'Calendar',
	react = 'react',
	redux = 'redux',
	sass = 'sass',
	materialui = 'materialui',
	webpack = 'webpack',
	nodejs = 'nodejs',
	mongodb = 'mongodb',
	gitlab = 'gitlab',
	linkedin = 'linkedin',
	github = 'github',
	facebook = 'facebook',
	phone = 'phone',
	home = 'home',
	email = 'email',
	pieChart = 'pieChart',
	localLibrary = 'localLibrary',
	hotel = 'hotel',
	Running = 'Running',
	Inventory = 'Inventory',
	RemoveShoppingCart = 'RemoveShoppingCart',
}

export interface IconProps {
	iconName: ICON_NAMES;
	className?: string;
	onClick?: React.MouseEventHandler;
	style?: object;
}

export const ICON_NAMES_VALUES = Object.freeze(Object.values(ICON_NAMES));

export const getIcon = (
	iconName: IconProps["iconName"],
	className: IconProps["className"],
	onClick: IconProps["onClick"],
	style: IconProps["style"]
) => {
	switch (iconName) {
		case ICON_NAMES.add: return <Add className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.arrowDown: return <KeyboardArrowDown className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.arrowLeft: return <ArrowBack className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.arrowRight: return <ArrowForward className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.arrowUp: return <KeyboardArrowUp className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Badge: return <AssignmentInd className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.book: return <BookRounded className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Build: return <Build className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.cancel: return <Cancel className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Cash: return <Euro className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.critical: return <PriorityHigh className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Check: return <Check className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.dead: return <Remove className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.delete: return <Delete className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.ExpandLess: return <ExpandLess className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.ExpandMore: return <ExpandMore className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.kebabMenu: return <MoreVert className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Language: return <Language className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.modify: return <Create className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Movies: return <LocalMovies className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Photo: return <Photo className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Power: return <PowerSettingsNew className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Recipes: return <Restaurant className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.save: return <Save className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.search: return <Search className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Todo: return <PlaylistAddCheck className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Work: return <Work className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.warning: return <Warning className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Tv: return <Tv className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Star: return <Star className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Calendar: return <CalendarToday className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.react: return <FaReact className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.redux: return <SiRedux className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.sass: return <FaSass className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.materialui: return <MaterialUiLogo className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.webpack: return <SiWebpack className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.nodejs: return <FaNodeJs className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.mongodb: return <SiMongodb className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.gitlab: return <FaGitlab className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.linkedin: return <FaLinkedin className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.github: return <FaGithub className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.facebook: return <FaFacebook className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.phone: return <PhoneAndroid className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.home: return <Home className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.email: return <Email className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.reload: return <Loop className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.pieChart: return <PieChart className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.localLibrary: return <LocalLibrary className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.hotel: return <Hotel className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Gym: return <FitnessCenter className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Running: return <RunCircleOutlined className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.Inventory: return <Inventory className={className} onClick={onClick} style={style} />;
		case ICON_NAMES.RemoveShoppingCart: return <RemoveShoppingCart className={className} onClick={onClick} style={style} />;
		default: return null;
	}
}

export const Icon: React.FunctionComponent<IconProps> = ({ iconName, className, onClick, style }) => {
	return getIcon(iconName, className, onClick, style);
}

export default Icon;